// @flow
import type { Saga } from "redux-saga";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
  type Filters,
  type Sorting, changeTablePage,
} from "@fas/cpa-state-manager/redux/actions/table";
import isEqual from "lodash.isequal";
import { GET_ACTIVE_BRANDS_SAGA, BRANDS_TABLE } from "../../helpers/constants";
import { getActiveBrands } from "../../services/activeBrandsApi";
import type { ActiveBrandsPayloadItem } from "../../containers/ActiveBrands/types/ActiveBrands.types";
import { getFirstAuthProductCompany } from "../../services/request";

let oldFilters;

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getActiveBrandsListLoading", true));

    const filters: Filters = yield select(getTableFilters, BRANDS_TABLE);
    filters.customer = getFirstAuthProductCompany() || "";
    if (oldFilters && !isEqual(filters, oldFilters)) {
      yield put(changeTablePage(BRANDS_TABLE, 1));
    }
    oldFilters = filters;

    const limit: number = yield select(getTablePageSize, BRANDS_TABLE);
    const page: number = yield select(getTablePage, BRANDS_TABLE);
    const sorting: Sorting = yield select(getTableSorting, BRANDS_TABLE);

    const {
      data: {
        data,
        count,
      },
    }: { data: { data: ActiveBrandsPayloadItem[], count: number }} = yield call(getActiveBrands, {
      page,
      limit,
      filters: Object.keys(filters).reduce((acc, key) => { // clear empty filter value
        if (filters[key] === "") {
          return acc;
        }
        return { ...acc, [key]: filters[key] };
      }, {}),
      sorting,
      headers: ["domain", "vertical", "platform", "niche", "url", "image"],
    });

    yield put(changeTableItemsTotalAmount(BRANDS_TABLE, count));
    yield put(setTableData(BRANDS_TABLE, data));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getActiveBrandsListLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ACTIVE_BRANDS_SAGA, (makeFetch: Function));
}
