// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Box, Button, CircularProgress, Grid, Typography,
} from "@mui/material";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import dayjs from "dayjs";
import en from "dayjs/locale/en-gb";
import GetApp from "@mui/icons-material/GetApp";
import { createStyles, makeStyles } from "@mui/styles";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { TableComponent, TablePagination } from "../TableComponent";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import { makeStylesTyped, mapOptions, presets } from "../../helpers/generators";
import { setQueryFilter } from "../../helpers/queryFilter";
import DateRangeFooter from "../DateRangeFooter";

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
  onDownloadTransactionReport: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  onGetCountriesDropdownData: () => mixed,
  onGetPlatformsDropdownData: () => mixed,
  onGetAgesDropdownData: () => mixed,
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
  fields: string[],
|}

const useStyles = makeStylesTyped(() => ({
  dateRange: {
    width: "253px",
  },
  input: {
    width: "180px",
  },
  label: {},
  download: {
    marginLeft: "20px",
  },
  downloadIcon: {
    margin: "0 0 0 10px",
  },
  filter: {
    flexWrap: "wrap",
    display: "flex",
    gap: "20px",
    flex: "1",
  },
  filterAdd: {
    width: "100%",
    flexWrap: "wrap",
    display: "flex",
    gap: "20px",
    flex: "1",
    padding: "10px",
    background: "rgba(180, 90, 212, 0.05)",
    marginTop: "20px",
  },
}));

const dateRangePickerStyles = makeStyles(() => createStyles({
  paper: {
    border: "1px solid #D9D9D9",
    background: "#FFF",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
    padding: "20px",
  },
}));

const TransactionReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
  onDownloadTransactionReport,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  onGetCountriesDropdownData,
  onGetPlatformsDropdownData,
  onGetAgesDropdownData,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
  fields,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);

  useEffect(() => {
    onGetCountriesDropdownData();
    onGetPlatformsDropdownData();
    onGetAgesDropdownData();
  }, []);

  useEffect(() => {
    setQueryFilter(filters);
  }, [filters]);

  const columns: Array<Column<ItemData>> = [
    {
      label: "Date",
      field: "date",
      key: "date",
    },
    {
      label: "Company Name",
      field: "customer",
      key: "customer",
    },
    {
      label: "Transaction Id",
      field: "invoiceId",
      key: "invoiceId",
    },
    {
      label: "Department",
      field: "department",
      key: "department",
    },
    {
      label: "Country",
      field: "country",
      key: "country",
    },
    {
      label: "Platform",
      field: "platform",
      key: "platform",
    },
    {
      label: "Age Group",
      field: "age",
      key: "age",
    },
    {
      label: "Leads",
      field: "quantity",
      key: "quantity",
    },
    {
      label: "Commission",
      field: "cost",
      key: "cost",
    },
    {
      label: "Total Commission",
      field: "amount",
      key: "amount",
    },
  ].filter((column: Column<ItemData>): boolean => fields.includes(column.field));

  const totalColumns: Array<Column<TotalData>> = [
    {
      label: "",
      field: "",
      key: "summary",
      render: (): string => "Summary",
    },
    {
      label: "",
      field: "",
      key: "quantity",
      render: ({ row }: { row: TotalData }): string => `${String(filters.currency) || "$"} ${row.amount}`,
      colSpan: columns.length - 1,
    },
  ];

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  const handleFiltersChange: (Filters) => void = (newFilters) => {
    onChangeTableFilters(TRANSACTION_TABLE, newFilters);
  };

  const handlePageChange: (number) => void = (newPage) => {
    onChangeTablePage(TRANSACTION_TABLE, newPage);
    onGetData();
  };

  const handlePageSizeChange: (number) => void = (newPageSize) => {
    onChangeTablePage(TRANSACTION_TABLE, 1);
    onChangeTablePageSize(TRANSACTION_TABLE, newPageSize);
    onGetData();
  };

  const handleRangeChangeDateRangePicker = ({ startDate, endDate }) => {
    onChangeTableFilters(TRANSACTION_TABLE, { dateFrom: startDate, dateTo: endDate });
  };

  const dynamicFieldsOptions: DropDownObjItemType[] = [
    { value: "customer", label: "Company Name" },
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: "20px" }}>
          <Typography variant="h3">Transaction Report</Typography>
          <Box>
            <Button
              className={classes.download}
              variant="secondary"
              name="download"
              disabled={loading}
              endIcon={<GetApp className={classes.downloadIcon} />}
              onClick={onDownloadTransactionReport}
            >
              Download CSV
            </Button>
          </Box>
        </Box>
        <Box display="flex" sx={{ marginBottom: "40px" }}>
          <Box>
            <Box className={classes.filter}>
              <FilterBuilder
                data-testid="filters"
                // $FlowFixMe
                filters={filters}
                // $FlowFixMe
                onFiltersChange={handleFiltersChange}
                items={[
                  {
                    type: "select",
                    filterKey: "department",
                    filterProps: {
                      label: "Department",
                      className: classes.input,
                      classes: { formLabel: classes.label },
                      disabled: loading,
                      "data-testid": "filter-department",
                      options: [
                        { value: "", label: "All" },
                        ...departmentsDropdown,
                      ].map(mapOptions),
                    },
                  },
                  {
                    type: "select",
                    filterKey: "country",
                    filterProps: {
                      label: "Country",
                      className: classes.input,
                      classes: { formLabel: classes.label },
                      disabled: loading,
                      "data-testid": "filter-country",
                      options: [
                        { value: "", label: "All" },
                        ...countriesDropdown,
                      ].map(mapOptions),
                    },
                  },
                  {
                    type: "select",
                    filterKey: "customer",
                    filterProps: {
                      label: "Company Name",
                      className: classes.input,
                      classes: { formLabel: classes.label },
                      disabled: loading,
                      "data-testid": "filter-customer",
                      options: [
                        { value: "", label: "All" },
                        ...customersDropdown,
                      ].map(mapOptions),
                    },
                  },
                  {
                    type: "textField",
                    filterKey: "invoiceId",
                    filterProps: {
                      label: "Transaction id",
                      className: classes.input,
                      classes: { formLabel: classes.label },
                      disabled: loading,
                      type: "number",
                      "data-testid": "filter-invoiceId",
                    },
                  },
                  {
                    type: "select",
                    filterKey: "platform",
                    filterProps: {
                      label: "Platform",
                      className: classes.input,
                      classes: { formLabel: classes.label },
                      disabled: loading,
                      "data-testid": "filter-platform",
                      options: [
                        { value: "", label: "All" },
                        ...platformsDropdown,
                      ].map(mapOptions),
                    },
                  },
                  {
                    type: "select",
                    filterKey: "age",
                    filterProps: {
                      label: "Age Group",
                      className: classes.input,
                      classes: { formLabel: classes.label },
                      disabled: loading,
                      "data-testid": "filter-age",
                      options: [
                        { value: "", label: "All" },
                        ...agesDropdown,
                      ].map(mapOptions),
                    },
                  },
                  {
                    type: "dateRangePicker",
                    filterKey: "dateRangePicker",
                    filterProps: {
                      disabled: loading,
                      className: classes.dateRange,
                      classes: dateRangePickerStyles(),
                      label: "Date range",
                      startDate: filters.dateFrom,
                      endDate: filters.dateTo,
                      onChangeDate: handleRangeChangeDateRangePicker,
                      onChangePreset: handleRangeChangeDateRangePicker,
                      presets,
                      locale: en,
                      formatDate: (date) => dayjs(date).format("DD-MM-YYYY"),
                      "data-testid": "filter-date-range-picker",
                      FooterComponent: DateRangeFooter,
                    },
                  },
                ]}
              />
            </Box>
            { isAdvancedFilterEnabled && (
              <Box className={classes.filterAdd}>
                <FilterBuilder
                  // $FlowFixMe wrong type
                  filters={filters}
                  onFiltersChange={handleFiltersChange}
                  items={[
                    {
                      type: "select",
                      filterKey: "currency",
                      filterProps: {
                        label: "Conversion currency",
                        className: classes.input,
                        classes: { formLabel: classes.label },
                        disabled: loading,
                        "data-testid": "filter-currency",
                        disableClearable: true,
                        options: [
                          { value: "$", label: "Only in $" },
                          { value: "€", label: "Only in €" },
                          { value: "£", label: "Only in £" },
                        ].map(mapOptions),
                      },
                    },
                    {
                      type: "multiSelect",
                      filterKey: "dynamicFields",
                      filterProps: {
                        label: "Show columns",
                        className: classes.dateRange,
                        classes: { formLabel: classes.label },
                        disabled: loading,
                        "data-testid": "filter-dynamicFields",
                        options: dynamicFieldsOptions.map(mapOptions),
                      },
                    },
                  ]}
                />
              </Box>
            )}
          </Box>

          <Box alignSelf="flex-start" display="flex" flexShrink={0} sx={{ paddingTop: "23px" }}>
            <Box>
              <Button
                variant={isAdvancedFilterEnabled ? "main" : "outlined"}
                name="advancedFilter"
                onClick={() => {
                  setIsAdvancedFilterEnabled(!isAdvancedFilterEnabled);
                }}
                sx={{ marginRight: "20px" }}
              >
                Advanced filter
              </Button>
            </Box>
            <Box>
              <Button
                variant="main"
                name="applyFilters"
                disabled={loading}
                onClick={onGetData}
              >
                Apply filters
              </Button>
            </Box>
          </Box>
        </Box>
        { loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { isShowNoDataMessage && (
          <Grid container justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Grid>
        )}
        { isShowData && (
          <>
            <TableComponent
              data={data}
              columns={columns}
              totalData={totalData}
              totalColumns={totalColumns}
            />
            <TablePagination
              page={page}
              pageSize={pageSize}
              count={total}
              onChangePage={handlePageChange}
              onChangePageSize={handlePageSizeChange}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionReport;
