// @flow
import {
  SET_AUTH_DATA_SAGA,
} from "../../helpers/constants";

export type SetAuthDataSaga = {|
  type: typeof SET_AUTH_DATA_SAGA,
  companies: string[],
  history: *,
  redirect: string,
  expires: Date,
|}

export type Actions = SetAuthDataSaga

export const setAuthDataSaga: ({
  companies: string[],
  history: *,
  redirect: string,
  expires: Date,
}) => SetAuthDataSaga = ({
  companies,
  history,
  redirect,
  expires,
}) => ({
  type: SET_AUTH_DATA_SAGA,
  companies,
  history,
  redirect,
  expires,
});
