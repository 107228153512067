// @flow
const colors = {
  main: "#000",
  primary: "#B45AD4",
  secondary: "#FFDE5F",
  mutted: "rgba(0, 0, 0, 0.50)",
  dark: "#2D2E2F",
  transparent: "rgba(0, 0, 0, 0.1)",
  error: "#FE6060",
  success: "#3CEBC1",
};

const themeDefaultObject: mixed = {
  theme: "default",
  props: {},
  typography: {
    fontFamily: "Poppins",
    lineHeight: "inherit",
    h1: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: 1.4,
    },
    h2: {
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: 1.333,
    },
    h3: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: 1.5,
      color: colors.primary,
    },
    h4: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: 1.428,
    },
    h5: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: 1.444,
    },
    h6: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: 1.555,
    },
    body: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.428,
    },
    bodyBold: {
      fontSize: "14px",
      lineHeight: 1.428,
      fontWeight: 700,
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 1.333,
    },
    bodySmallBold: {
      fontSize: "12px",
      lineHeight: 1.428,
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    background: {
      light: "#3A1F4E",
      default: "#2E1940",
      dark: "#221333",
      white: "#fff",
    },
    text: {
      main: colors.main,
      primary: colors.primary,
      secondary: colors.secondary,
      mutted: colors.mutted,
      dark: colors.dark,
    },
    primary: {
      light: colors.primary,
      main: colors.primary,
      dark: colors.primary,
    },
    error: {
      main: colors.error,
      light: colors.error,
      dark: colors.error,
    },
    success: {
      main: colors.success,
      light: colors.success,
      dark: colors.success,
    },
    transparent: {
      default: colors.transparent,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          color: colors.main,
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: 1.5,
          backgroundColor: "#2E1940",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid #D9D9D9",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: 1.428,
          color: colors.main,
          padding: "10px",
          textAlign: "center",
        },
        head: {
          fontWeight: 700,
          color: colors.primary,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: "14px",
          borderRadius: "20px",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
        },
        listbox: {
          padding: "8px 0",
        },
        option: {
          fontSize: "14px",
          padding: "0 15px!important",
          color: colors.mutted,
          "& + .MuiAutocomplete-option": {
            marginTop: "5px",
          },
          "&[aria-selected=\"true\"], &.Mui-focused": {
            color: `${colors.main}!important`,
            background: "transparent!important",
          },
        },
        noOptions: {
          color: colors.mutted,
          fontSize: "14px",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& .MuiTouchRipple": {
            background: "rgba(255, 255, 255, 0.1)",
          },
          ...(ownerState.variant === "primary" && {
            minWidth: "max-content",
            display: "flex",
            alignItems: "center",
            height: "40px",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0 24px",
            borderRadius: "20px",
            textTransform: "capitalize",
            boxShadow: "none",
            color: colors.main,
            backgroundColor: "#fff",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: colors.primary,
              color: "#fff",
            },
          }),
          // $FlowFixMe
          ...(ownerState.variant === "main" && {
            minWidth: "max-content",
            display: "flex",
            alignItems: "center",
            height: "40px",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0 24px",
            borderRadius: "20px",
            textTransform: "capitalize",
            boxShadow: "none",
            color: "#fff",
            backgroundColor: colors.primary,
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#8E00C0",
            },
          }),
          ...(ownerState.variant === "secondary" && {
            minWidth: "max-content",
            display: "flex",
            alignItems: "center",
            height: "40px",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0 16px 0 24px",
            borderRadius: "20px",
            textTransform: "capitalize",
            boxShadow: "none",
            color: "#fff",
            backgroundColor: colors.success,
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#009D77",
            },
          }),
          ...(ownerState.variant === "outlined" && {
            minWidth: "max-content",
            display: "flex",
            alignItems: "center",
            height: "40px",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0 24px",
            borderRadius: "20px",
            textTransform: "capitalize",
            boxShadow: "none",
            color: colors.primary,
            border: `1px solid ${colors.primary}`,
            "&:hover": {
              boxShadow: "none",
              backgroundColor: colors.primary,
              color: "#fff",
            },
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0",
          padding: "0",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "underline",
          color: colors.main,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "1.5",
          color: colors.main,
          marginBottom: "5px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          height: "40px",
          border: "1px solid #D9D9D9",
          background: "#fff",
          padding: "0 10px 0 15px",
          borderRadius: "20px",
          color: colors.main,
          fontSize: "14px",

          "& input": {
            padding: 0,
            border: "none",
          },

          "& .MuiInputAdornment-positionEnd": {
            margin: "0 10px 0 0",
          },
          "& .MuiInputAdornment-positionStart": {
            margin: "0 0 0 10px",
          },
          "&.Mui-disabled": {
            color: `${colors.mutted}!important`,
            ".MuiInputAdornment-positionStart, .MuiInputAdornment-positionEnd": {
              color: `${colors.mutted}!important`,
            },
          },
          "&.Mui-error": {
            borderColor: colors.error,
            color: colors.error,
            "& .MuiInputAdornment-positionEnd, & .MuiInputAdornment-positionStart": {
              color: colors.error,
            },
          },
          "& .MuiSvgIcon-root": {
            color: "rgba(0, 0, 0, 0.25)",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
          },
          "&.MuiOutlinedInput-root": {
            boxSizing: "border-box",
            height: "40px",
            border: "1px solid #D9D9D9",
            background: "#fff",
            padding: "0 10px 0 15px",
            borderRadius: "20px",
            color: colors.main,
            fontSize: "14px",
          },
        },
        input: {
          fontSize: "14px",
          lineHeight: 1.42,
          padding: 0,
          "WebkitTextFillColor": "initial",
          color: "inherit",
          "&.Mui-disabled": {
            cursor: "not-allowed",
            color: "inherit",
            "WebkitTextFillColor": "initial",
          },
          "&::placeholder, &::-webkit-input-placeholder": {
            color: colors.mutted,
            opacity: "1",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },

    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.mutted,
          margin: "5px 0 0",
          fontSize: "10px",
          "&:empty": {
            display: "none",
          },
        },
      },

    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "1.428",
          background: "rgba(0, 0, 0)",
          color: colors.mutted,
          backgroundBlendMode: "multiply",
          border: "1px solid rgba(218, 224, 227, 0.40)",
          minWidth: "120px",
          "&:hover": {
            background: colors.transparent,
          },
          "& .MuiSvgIcon-root": {
            color: colors.main,
            userSelect: "none",
            pointerEvents: "none",
            width: "1em",
            height: "1em",
            display: "inline-block",
            flexShrink: "0",
            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            fontSize: "1.5rem",
            position: "absolute",
            right: "7px",
            top: "calc(50% - .5em)",
          },
          "&.Mui-disabled": {
            color: colors.mutted,
            "& .MuiSvgIcon-root": {
              color: colors.mutted,
            },
          },
        },
      },

    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "transparent",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          marginRight: "8px",
          padding: "0",
          "&.Mui-checked": {
            color: "transparent",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "16px",
          },
          "& + .MuiTypography-root": {
            fontSize: "14px",
            lineHeight: "1.428",
          },
        },
      },

    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0",
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255, 0.65)",
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          padding: "0 20px 0 0",
          "& + .MuiBox-root": {
            border: "none",
            padding: "0",
            "& .MuiButtonBase-root": {
              padding: "6px 10px",
              color: colors.main,
              fontSize: "12px",
              lineHeight: 1.5,
              "&:hover": {
                background: "#009D77",
                color: "#fff",
              },
            },
          },
          "& .MuiPickerStaticWrapper-content": {
            minWidth: "220px",
          },
          "& .MuiCalendarOrClockPicker-root > div": {
            width: "auto",
          },
          "& .MuiCalendarPicker-root": {
            width: "unset",
            maxHeight: "unset",
          },
          "& .MuiDayPicker-slideTransition": {
            minHeight: "160px",
          },
          "& .MuiPickersCalendarHeader-root": {
            padding: "0",
            margin: "0 0 10px",
            minHeight: "auto",
            maxHeight: "none",
          },
          "& .MuiPickersCalendarHeader-label": {
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: 1.5,
            color: colors.main,
          },
          "& .MuiPickersArrowSwitcher-spacer": {
            width: "24px",
          },
          "& .MuiPickersArrowSwitcher-button": {
            padding: 0,
            "& .MuiSvgIcon-root": {
              width: "24px",
              height: "24px",
            },
          },
          "& .MuiDayPicker-weekDayLabel": {
            color: colors.main,
            fontSize: "12px",
            lineHeight: 1.5,
            margin: "0 1px",
            width: "30px",
            height: "30px",
          },
          "& .MuiPickersDay-root": {
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: 1.5,
            height: "30px",
            width: "30px",
            background: "#fff",
            color: colors.main,
            borderRadius: 0,
            margin: "0",
            "&:hover": {
              background: "#009D77",
              color: "#fff",
            },
            "&.Mui-selected": {
              background: colors.success,
              color: "#fff",
              "&:hover": {
                background: "#009D77",
              },
            },
          },
          "& .MuiDayPicker-monthContainer": {
            display: "flex",
            flexDirection: "column",
            gap: "1px",
          },
          "& .MuiDayPicker-weekContainer": {
            gap: "1px",
            margin: "0",
            "&:first-of-type": {
              marginTop: "5px",
            },
          },
          "& .MuiYearPicker-root": {
            gap: "1px",
            padding: 0,
            "& .PrivatePickersYear-root": {
              flexBasis: "unset",
            },
            "& button": {
              padding: "6px 4px",
              margin: "0",
              height: "auto",
              background: "#fff",
              color: colors.main,
              fontSize: "12px",
              lineHeight: 1.5,
              borderRadius: 0,
              "&:hover": {
                background: "#009D77",
                color: "#fff",
              },
              "&.Mui-selected": {
                background: colors.success,
                color: "#fff",
                "&:hover": {
                  background: "#009D77",
                },
              },
            },
          },
        },
      },

    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          marginTop: "20px",
          color: colors.main,
        },
        selectLabel: {
          fontSize: "14px",
        },
        input: {
          margin: "0 40px 0 10px",
          padding: 0,
        },
        select: {
          padding: "0 34px 0 15px",
          margin: "0",
        },
        menuItem: {
          padding: "0 15px!important",
          color: colors.mutted,
          "& + .MuiMenuItem-root": {
            marginTop: "5px",
          },
          "&[aria-selected=\"true\"], &:hover": {
            color: `${colors.main}!important`,
            background: "transparent!important",
          },
        },
        toolbar: {
          padding: "0!important",
          minHeight: "40px!important",
        },
        displayedRows: {
          fontSize: "14px",
        },
        actions: {
          marginLeft: "40px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper": {
            borderRadius: "20px",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: colors.main,
          "& .MuiSvgIcon-root": {
            color: colors.mutted,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "20px",
          padding: "40px",
        },
      },
    },
  },
};

export default themeDefaultObject;
